import { LinkOutlined, DisconnectOutlined } from "@ant-design/icons";
import {
  API,
  InlineTool,
  InlineToolConstructorOptions,
  SanitizerConfig,
} from "@editorjs/editorjs";
import React from "react";
import ReactDOM from "react-dom";

import SelectionUtils from "../selectionUtils";

class InlineLinkTool implements InlineTool {
  api: API;
  selection: SelectionUtils;
  static get isInline() {
    return true;
  }

  /**
   * Sanitizer Rule
   * Leave `<a>` tags
   * @return {object}
   */
  static get sanitize(): SanitizerConfig {
    return {
      a: {
        href: true,
        ref: "external",
        target: true,
        title: true,
      },
    };
  }

  private value: string | null = null;

  /**
   * Elements
   */
  private nodes: {
    button: HTMLButtonElement | null;
    input: HTMLInputElement | null;
  } = {
    button: null,
    input: null,
  };

  /**
   * Styles
   */
  private readonly CSS = {
    button: "ce-inline-tool",
    buttonActive: "ce-inline-tool--active",
    buttonModifier: "ce-inline-tool--link",
    buttonUnlink: "ce-inline-tool--unlink",
    container: "ce-inline-tool-container",
    input: "ce-inline-tool-input",
    inputShowed: "ce-inline-tool-input--showed",
    inputHidden: "ce-inline-tool-input--hidden",
  };

  constructor({ api }: InlineToolConstructorOptions) {
    this.api = api;
    this.selection = new SelectionUtils();
  }

  render(): HTMLElement {
    this.nodes.button = document.createElement("button");
    this.nodes.button.type = "button";
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);

    ReactDOM.render(
      <React.Fragment>
        <LinkOutlined
          className="icon icon--link"
          style={{
            height: 14,
          }}
        />
        <DisconnectOutlined
          className="icon icon--unlink"
          style={{
            height: 14,
          }}
        />
      </React.Fragment>,
      this.nodes.button
    );

    return this.nodes.button;
  }

  checkState(): boolean {
    const anchorTag = this.api.selection?.findParentTag(
      "A"
    ) as HTMLAnchorElement | null;

    if (anchorTag && anchorTag.getAttribute("rel") === "external") {
      this.nodes.button?.classList.add(this.CSS.buttonUnlink);
      this.nodes.button?.classList.add(this.CSS.buttonActive);
      this.openActions();

      this.nodes.input?.setAttribute("value", this.value!);

      this.selection.save();
    } else {
      this.nodes.button?.classList.remove(this.CSS.buttonUnlink);
      this.nodes.button?.classList.remove(this.CSS.buttonActive);
    }

    return !!(anchorTag && anchorTag.getAttribute("rel") === "external");
  }
  surround(range: Range): void {
    /**
     * Range will be null when user makes second click on the 'link icon' to close opened input
     */
    if (range) {
      /**
       * Save selection before change focus to the input
       */
      if (!this.inputOpened) {
        /** Create blue background instead of selection */
        this.selection.setFakeBackground();
        this.selection.save();
      } else {
        this.selection.restore();
        this.selection.removeFakeBackground();
      }
      const parentAnchor = this.api.selection.findParentTag("A");

      /**
       * Unlink icon pressed
       */
      if (parentAnchor) {
        this.api.selection.expandToTag(parentAnchor);
        this.unlink();
        this.closeActions();
        this.checkState();
        this.api.toolbar.close();

        return;
      }
    }
    this.toggleActions();
  }
  renderActions?(): HTMLElement {
    this.hydrateValue();

    this.nodes.input = document.createElement("input");
    this.nodes.input.classList.add(this.CSS.input);
    this.nodes.input.placeholder = "https://";
    this.nodes.input.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        this.handlePressEnter(event);
      }
    });

    return this.nodes.input;
  }
  clear(): void {
    this.closeActions();
  }

  hydrateValue() {
    const selection = window.getSelection();
    if (!selection) return;

    const anchorTag = this.api.selection?.findParentTag(
      "A"
    ) as HTMLAnchorElement | null;
    if (!anchorTag) return;

    const hrefAttr = anchorTag.getAttribute("href");
    this.value = hrefAttr;
  }

  private inputOpened = false;
  private toggleActions(): void {
    if (!this.inputOpened) {
      this.openActions(true);
    } else {
      this.closeActions(false);
    }
  }
  /**
   * @param {boolean} needFocus - on link creation we need to focus input. On editing - nope.
   */
  private openActions(needFocus = false): void {
    this.nodes.input?.classList.add(this.CSS.inputShowed);
    if (needFocus) {
      this.nodes.input?.focus();
    }
    this.inputOpened = true;
  }
  /**
   * Close input
   *
   * @param {boolean} clearSavedSelection — we don't need to clear saved selection
   *                                        on toggle-clicks on the icon of opened Toolbar
   */
  private closeActions(clearSavedSelection = true): void {
    if (this.selection.isFakeBackgroundEnabled) {
      // if actions is broken by other selection We need to save new selection
      const currentSelection = new SelectionUtils();
      currentSelection.save();

      this.selection.restore();
      this.selection.removeFakeBackground();

      // and recover new selection after removing fake background
      currentSelection.restore();
    }

    this.nodes.input?.classList.remove(this.CSS.inputShowed);
    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
    this.inputOpened = false;
  }

  private handlePressEnter(event: KeyboardEvent): void {
    let { value } = event.target as HTMLInputElement;

    if (!value.trim()) {
      this.selection.restore();
      this.unlink();
      event.preventDefault();
      this.closeActions();

      return;
    }

    if (!this.validateURL(value)) {
      this.api.notifier.show({
        message: "Pasted link is not valid.",
        style: "error",
      });
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    value = this.prepareLink(value);

    this.selection.restore();
    this.selection.removeFakeBackground();

    this.insertLink(value);

    event.preventDefault();
    event.stopPropagation();
    this.selection.collapseToEnd();
    this.api.inlineToolbar.close();
  }

  /**
   * Native Document's commands for insertHTML and unlink
   * @see https://stackoverflow.com/a/23891233/1238150
   */
  private readonly commandLink: string = "insertHTML";
  private readonly commandUnlink: string = "unlink";
  /**
   * Inserts <a> tag with "href"
   *
   * @param {string} link - "href" value
   */
  private insertLink(link: string): void {
    /**
     * Edit all link, not selected part
     */
    const anchorTag = this.api.selection.findParentTag("A");

    if (anchorTag) {
      this.api.selection.expandToTag(anchorTag);
    }

    document.execCommand(
      this.commandLink,
      false,
      `<a rel="external" href="${link}">${SelectionUtils.text}</a>`
    );
  }
  private unlink(): void {
    document.execCommand(this.commandUnlink);
  }

  /**
   * Detects if passed string is URL
   * @param  {string}  str
   * @return {Boolean}
   */
  private validateURL(str: string): boolean {
    /**
     * Don't allow spaces
     */
    return !/\s/.test(str);
  }
  /**
   * Process link before injection
   * - sanitize
   * - add protocol for links like 'google.com'
   * @param {string} link - raw user input
   */
  private prepareLink(link: string): string {
    let newLink = link;
    newLink = newLink.trim();
    newLink = this.addProtocol(newLink);
    return newLink;
  }
  /**
   * Add 'http' protocol to the links like 'example.com', 'google.com'
   * @param {String} link
   */
  private addProtocol(link: string): string {
    let newLink = link;

    /**
     * If protocol already exists, do nothing
     */
    if (/^(\w+):(\/\/)?/.test(newLink)) {
      return newLink;
    }

    /**
     * We need to add missed HTTP protocol to the link, but skip 2 cases:
     *     1) Internal links like "/general"
     *     2) Anchors looks like "#results"
     *     3) Protocol-relative URLs like "//google.com"
     */
    const isInternal = /^\/[^/\s]/.test(newLink);
    const isAnchor = newLink.substring(0, 1) === "#";
    const isProtocolRelative = /^\/\/[^/\s]/.test(newLink);

    if (!isInternal && !isAnchor && !isProtocolRelative) {
      newLink = `https://${newLink}`;
    }

    return newLink;
  }
}

export default InlineLinkTool;
