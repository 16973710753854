const config = {
  local: {
    domain: "http://localhost:8080/api/admin",
    hostname: "localhost:8080",
  },
  development: {
    domain: "https://plat-dev-engagement-be.platform.evidation.dev/api/admin",
    hostname: "plat-dev-engagement-be.platform.evidation.dev",
  },
  production: {
    domain: "https://api.my.evidation.com/api/admin",
    hostname: "api.my.evidation.com",
  },
  staging: {
    domain: "https://api-stage.my.evidation.com/api/admin",
    hostname: "api-stage.my.evidation.com",
  },
  test: {
    domain: "http://localhost/api/admin",
    hostname: "localhost",
  },
} as const;

export default config;
