import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";

const usePublishTargetingRule = (
  offerId: string,
  queryOptions?: UseMutationOptions<void, PortalClientError>
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError>(
    (payload) =>
      client.post(`/offers/${offerId}/publish_targeting_rule`, payload),
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["offers", offerId]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default usePublishTargetingRule;
