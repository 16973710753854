import { Button, Image } from "antd";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { ClientProvider } from "../../../../client";
import SelectImageModal from "../../../../modals/SelectImageModal";

const ImageBlock = ({
  onDataChange,
  data,
  accessToken,
}: {
  data: any;
  onDataChange: (data: any) => any;
  accessToken?: string;
}) => {
  const { handleSubmit, watch, reset } = useForm({
    defaultValues: data,
  });
  useEffect(() => {
    const subscription = watch(() => handleSubmit(onDataChange)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onDataChange]);
  // because each block renders in its own react dom tree
  // we need to reinitialize the client provider for the image component
  const CLIENT_PROVIDER_ENVIRONMENT =
    window.env.REACT_APP_PORTAL_ENVIRONMENT ?? "staging";
  return (
    <ClientProvider
      environment={CLIENT_PROVIDER_ENVIRONMENT}
      getAccessToken={() => accessToken!}
    >
      <SelectImageModal
        kind=""
        // the object returned here is effectively the entire form for this ImageBlock
        // so we just reset the form with the new values.
        onSelectImage={(i) => reset(i)}
        renderButton={(open) => (
          <Button
            htmlType="button"
            onClick={open}
            style={{ height: "auto" }}
            title="click to edit"
            type="link"
          >
            <Image alt={watch("alt_text")} preview={false} src={watch("src")} />
          </Button>
        )}
        visibleDefault
      />
    </ClientProvider>
  );
};

export default ImageBlock;
