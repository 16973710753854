import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { ImageKind } from "./types";

const useImageKinds = <TData = JsonApiResponse<ImageKind[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<ImageKind[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<ImageKind[]>, PortalClientError, TData>(
    ["image_kinds"],
    () => client.get("/images/kinds"),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useImageKinds;
