import { ExportOutlined } from "@ant-design/icons";
import {
  API,
  InlineTool,
  InlineToolConstructorOptions,
} from "@editorjs/editorjs";
import { Select } from "antd";
import ReactDOM from "react-dom";

import { AppLink } from "../../../client/hooks/communicationConfigs/types";
import SelectionUtils from "../selectionUtils";

class InlineAppLinkTool implements InlineTool {
  api: API;
  selection: SelectionUtils;
  static get isInline() {
    return true;
  }

  /**
   * Sanitizer Rule
   * Leave <a> tags
   * @return {object}
   */
  static get sanitize(): EditorJS.SanitizerConfig {
    return {
      a: {
        href: true,
        rel: true,
        target: true,
        title: true,
      },
    };
  }

  private links: AppLink[];
  private templateVariableRegex = /\{\{LINK:(.*)\}\}/;

  private value: string | null = null;

  /**
   * Elements
   */
  private nodes: {
    button: HTMLButtonElement | null;
    actionContainer: HTMLDivElement | null;
    input: HTMLDivElement | null;
  } = {
    button: null,
    actionContainer: null,
    input: null,
  };

  /**
   * Styles
   */
  private readonly CSS = {
    button: "ce-inline-tool",
    buttonActive: "ce-inline-tool--active",
    buttonModifier: "ce-inline-tool--link",
    buttonUnlink: "ce-inline-tool--unlink",
    input: "ce-inline-tool-input",
    inputShowed: "ce-inline-tool-input--showed",
  };

  constructor({ api, config }: InlineToolConstructorOptions) {
    this.api = api;
    this.links = config.links;
    this.selection = new SelectionUtils();
  }

  render(): HTMLElement {
    this.nodes.button = document.createElement("button");
    this.nodes.button.type = "button";
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);

    ReactDOM.render(
      <ExportOutlined
        className="icon"
        style={{
          height: 14,
        }}
      />,
      this.nodes.button
    );

    return this.nodes.button;
  }

  checkState(): boolean {
    const anchorTag = this.api.selection?.findParentTag(
      "A"
    ) as HTMLAnchorElement | null;

    if (anchorTag && anchorTag.getAttribute("rel") !== "external") {
      this.nodes.button?.classList.add(this.CSS.buttonUnlink);
      this.nodes.button?.classList.add(this.CSS.buttonActive);
      this.openActions();

      this.selection.save();
    } else {
      this.nodes.button?.classList.remove(this.CSS.buttonUnlink);
      this.nodes.button?.classList.remove(this.CSS.buttonActive);
    }

    return !!(anchorTag && anchorTag.getAttribute("rel") !== "external");
  }
  surround(range: Range): void {
    /**
     * Range will be null when user makes second click on the 'link icon' to close opened input
     */
    if (range) {
      /**
       * Save selection before change focus to the input
       */
      if (!this.inputOpened) {
        /** Create blue background instead of selection */
        this.selection.setFakeBackground();
        this.selection.save();
      } else {
        this.selection.restore();
        this.selection.removeFakeBackground();
      }
      const parentAnchor = this.api.selection.findParentTag("A");

      /**
       * Unlink icon pressed
       */
      if (parentAnchor) {
        this.api.selection.expandToTag(parentAnchor);
        this.unlink();
        this.closeActions();
        this.checkState();
        this.api.toolbar.close();

        return;
      }
    }
    this.toggleActions();
  }
  renderActions?(): HTMLElement {
    this.nodes.actionContainer = document.createElement("div");
    this.nodes.actionContainer.classList.add("ce-inline-link-tool");
    this.nodes.actionContainer.classList.add(this.CSS.input);

    this.hydrateValue();

    ReactDOM.render(
      <Select
        allowClear
        className="ce-inline-link-tool__app-link"
        defaultValue={this.value}
        onChange={this.handleAppLinkChange.bind(this)}
        options={this.links?.map(({ display_text, id }) => ({
          label: display_text,
          value: id,
        }))}
        placeholder="Select App Link"
        style={{ width: "100%" }}
      />,
      this.nodes.actionContainer
    );

    this.nodes.input = this.nodes.actionContainer.querySelector(
      ".ce-inline-link-tool__app-link"
    );

    return this.nodes.actionContainer;
  }
  clear(): void {
    this.closeActions();
  }

  hydrateValue() {
    const selection = window.getSelection();
    if (!selection) return;

    const anchorTag = this.api.selection?.findParentTag(
      "A"
    ) as HTMLAnchorElement | null;
    if (!anchorTag) return;

    const hrefAttr = anchorTag.getAttribute("href");
    const regexResult = this.templateVariableRegex.exec(hrefAttr || "");
    this.value = regexResult?.[1] || null;
  }

  private inputOpened = false;
  private toggleActions(): void {
    if (!this.inputOpened) {
      this.openActions(true);
    } else {
      this.closeActions(false);
    }
  }
  /**
   * @param {boolean} needFocus - on link creation we need to focus input. On editing - nope.
   */
  private openActions(needFocus = false): void {
    this.nodes.actionContainer?.classList.add(this.CSS.inputShowed);
    if (needFocus) {
      this.nodes.input?.querySelector("input")?.focus();
    }
    this.inputOpened = true;
  }
  /**
   * Close input
   *
   * @param {boolean} clearSavedSelection — we don't need to clear saved selection
   *                                        on toggle-clicks on the icon of opened Toolbar
   */
  private closeActions(clearSavedSelection = true): void {
    if (this.selection.isFakeBackgroundEnabled) {
      // if actions is broken by other selection We need to save new selection
      const currentSelection = new SelectionUtils();

      currentSelection.save();

      this.selection.restore();
      this.selection.removeFakeBackground();

      // and recover new selection after removing fake background
      currentSelection.restore();
    }

    this.nodes.actionContainer?.classList.remove(this.CSS.inputShowed);
    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
    this.inputOpened = false;
  }

  private handleAppLinkChange(id: string | undefined): void {
    if (!id?.trim()) {
      this.selection.restore();
      this.unlink();
      this.closeActions();

      return;
    }

    this.selection.restore();
    this.selection.removeFakeBackground();

    this.insertLink(id);

    this.selection.collapseToEnd();
    this.api.inlineToolbar.close();
  }

  /**
   * Native Document's commands for insertHTML and unlink
   * @see https://stackoverflow.com/a/23891233/1238150
   */
  private readonly commandLink: string = "insertHTML";
  private readonly commandUnlink: string = "unlink";
  /**
   * Inserts <a> tag with "href"
   *
   * @param {string} id - "href" value
   */
  private insertLink(id: string): void {
    /**
     * Edit all link, not selected part
     */
    const anchorTag = this.api.selection.findParentTag("A");

    if (anchorTag) {
      this.api.selection.expandToTag(anchorTag);
    }

    const template = `{{LINK:${id}}}`;
    const link = this.links.find((link) => link.id === id)!;

    const target = link.open_externally ? 'target="_blank"' : "";
    const title = link.alt_text ? `title="${link.alt_text}"` : "";

    document.execCommand(
      this.commandLink,
      false,
      `<a href="${template}" ${target} ${title}>${SelectionUtils.text}</a>`
    );
  }
  private unlink(): void {
    document.execCommand(this.commandUnlink);
  }
}

export default InlineAppLinkTool;
