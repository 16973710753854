import { Experiment } from "./types";
import useExperiments from "./useExperiments";

const getExperimentByKey = ({
  key,
  experiments,
}: {
  key: string;
  experiments: Experiment[];
}) => experiments.find((experiment) => experiment.name === key);

const useExperimentByKey = ({ key }: { key: string }) => {
  const { data, error, isLoading } = useExperiments();

  const experiment = data?.data
    ? getExperimentByKey({ key, experiments: data.data })
    : undefined;

  return {
    experiment,
    error,
    isLoading,
  };
};

export default useExperimentByKey;
