import ReactDOM from "react-dom";

import ImageBlock from "./Image";

export default class Image {
  data = {
    key: "",
    src: "https://place-hold.it/500x300",
  };
  nodes: { holder: any } = { holder: null };
  readOnly = false;
  api = null;
  config: { accessToken?: string } = { accessToken: undefined };
  constructor({
    data,
    api,
    readOnly,
    config,
  }: {
    data: any;
    api: any;
    readOnly: boolean;
    config: { accessToken: string };
  }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = config;
    this.data = { ...this.data, ...data };
  }

  static get toolbox() {
    return {
      title: "Image",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.125 3.125H1.875C1.5293 3.125 1.25 3.4043 1.25 3.75V16.25C1.25 16.5957 1.5293 16.875 1.875 16.875H18.125C18.4707 16.875 18.75 16.5957 18.75 16.25V3.75C18.75 3.4043 18.4707 3.125 18.125 3.125ZM6.60156 5.9375C7.29102 5.9375 7.85156 6.49805 7.85156 7.1875C7.85156 7.87695 7.29102 8.4375 6.60156 8.4375C5.91211 8.4375 5.35156 7.87695 5.35156 7.1875C5.35156 6.49805 5.91211 5.9375 6.60156 5.9375ZM16.6387 14.4746C16.6102 14.4985 16.5743 14.5116 16.5371 14.5117H3.46094C3.375 14.5117 3.30469 14.4414 3.30469 14.3555C3.30469 14.3184 3.31836 14.2832 3.3418 14.2539L6.66797 10.3086C6.72266 10.2422 6.82227 10.2344 6.88867 10.2891C6.89453 10.2949 6.90234 10.3008 6.9082 10.3086L8.84961 12.6133L11.9375 8.95117C11.9922 8.88477 12.0918 8.87695 12.1582 8.93164C12.1641 8.9375 12.1719 8.94336 12.1777 8.95117L16.6621 14.2559C16.7129 14.3203 16.7051 14.4199 16.6387 14.4746Z" fill="#1A1A1A"/>
            </svg>`,
    };
  }

  save() {
    return this.data;
  }

  render() {
    const rootNode = document.createElement("div");
    this.nodes.holder = rootNode;
    const onDataChange = (newData: any) => {
      this.data = {
        ...this.data,
        ...newData,
      };
    };

    ReactDOM.render(
      <ImageBlock
        accessToken={this.config.accessToken}
        data={this.data}
        onDataChange={onDataChange}
      />,
      rootNode
    );
    return this.nodes.holder;
  }
}
