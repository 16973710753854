import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { ResendRewardPayload, Reward } from "./types";

const useResendReward = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Reward>,
    PortalClientError,
    ResendRewardPayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<Reward>,
    PortalClientError,
    ResendRewardPayload
  >((payload) => client.post(`/rewards/${payload.id}/resend`), {
    ...queryOptions,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["rewards"]);
      queryOptions?.onSuccess?.(data, variables, context);
    },
  });
};

export default useResendReward;
